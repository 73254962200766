export const photos = [
  {
    src: "img/portfolio/01-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/02-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/03-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/04-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/05-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/06-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/07-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/08-small.jpg",
    width: 576,
    height: 768,
  },
  {
    src: "img/portfolio/09-small.jpg",
    width: 576,
    height: 768,
  },
];
