import React from "react";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "Do you have a retail storefront?",
      content:
        "No, we do not have a storefront to sell products at our nursery.",
    },
    {
      title: "Are you open to the public?",
      content: "No, we are not open to the public.",
    },
    {
      title: "Do you sell vegetables, trees or shrubs?",
      content:
        "We do not. Our specialty is hanging baskets, moss baskets, planters and annual flats.",
    },
    {
      title: "What is the best way to contact you?",
      content: "Through our email (holland.avenue.nursery@outlook.com).",
    },
    {
      title: "Do you take credit card?",
      content: "No, we can take cash, check or e-transfer to our main email.",
    },
    {
      title: "Do you participate in fundrasers?",
      content: "Yes we do, please contact us for more information.",
    },
    {
      title: "Can I order your moss baskets?",
      content:
        "Yes, but it is preferable that you pre-order 3 to 4 months in advance.",
    },
  ],
};

const styles = {
  bgColor: "#e5e5e5",
  rowTitleColor: "black",
};

const config = {
  tabFocus: true,
};

export const FAQ = (props) => {
  return (
    <div id="faq">
      <div className="container">
        <div className="section-title text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="row">
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
};
