import React from "react";
import BeforeAfterSlider from "react-before-after-slider";
import { useMediaQuery } from "react-responsive";

export const Landscaping = (props) => {
  const isSmaller = useMediaQuery({ query: `(max-width: 991.98px)` });
  return (
    <div id="landscaping" className="text-center">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h2>landscaping</h2>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="list-services">
              <h3>Our Services</h3>
              <ul>
                {props.data
                  ? props.data.Why.map((d, i) => (
                      <li className="checkmark" key={`${d}-${i}`}>
                        {d}
                      </li>
                    ))
                  : "loading"}
              </ul>
            </div>
            <div className="lawn-care">
              <h3>Lawn Care</h3>
              <p>{props.data ? props.data.LawnCareText : "loading..."}</p>
              <h5>Weekly Cuts</h5>
              <ul>
                {props.data
                  ? props.data.WeeklyCuts.map((d, i) => (
                      <div className="lawn" key={`${d}-${i}`}>
                        {d}
                      </div>
                    ))
                  : "loading"}
              </ul>
              <h5>Bi-Weekly Cuts</h5>
              <ul>
                {props.data
                  ? props.data.BiWeeklyCuts.map((d, i) => (
                      <div className="lawn" key={`${d}-${i}`}>
                        {d}
                      </div>
                    ))
                  : "loading"}
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="before-and-after-container">
              <BeforeAfterSlider
                className="before-and-after"
                before={"img/before.jpg"}
                after={"img/after.jpg"}
                width={isSmaller ? 352 : 440}
                height={isSmaller ? 464 : 580}
                defaultProgress={0.5}
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-6"></div>
        </div>
      </div>
    </div>
  );
};
