import React, { useState } from "react";

export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top navbar-custom"
    >
      <div className="navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul className="nav navbar-nav">
          <li>
            <a href="#about" className="page-scroll">
              About
            </a>
          </li>
          <li>
            <a href="#landscaping" className="page-scroll">
              landscaping
            </a>
          </li>
          <li>
            <a href="#testimonials" className="page-scroll">
              Testimonials
            </a>
          </li>
          <li>
            <a href="#gallery" className="page-scroll">
              Gallery
            </a>
          </li>
          <li>
            <a href="#faq" className="page-scroll">
              FAQs
            </a>
          </li>
          <li>
            <a href="#contact" className="page-scroll">
              Contact Us
            </a>
          </li>
        </ul>
        <img src="img/logo.png" width={175} className="logo" alt="" />
      </div>
    </nav>
  );
};
