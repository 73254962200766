import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Testimonials</h2>
          <div
            id="myCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-bs-target="#myCarousel"
                data-bs-slide-to={0}
                className="active"
              />
              <li data-bs-target="#myCarousel" data-bs-slide-to={1} />
              <li data-bs-target="#myCarousel" data-bs-slide-to={2} />
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <p>{props.data ? props.data.testimonial1 : "loading..."}</p>
                <p>{props.data ? `- ${props.data.name1}` : "loading..."}</p>
              </div>
              <div className="carousel-item">
                <p>{props.data ? props.data.testimonial2 : "loading..."}</p>
                <p>{props.data ? `- ${props.data.name2}` : "loading..."}</p>
              </div>
              <div className="carousel-item">
                <p>{props.data ? props.data.testimonial3 : "loading..."}</p>
                <p>{props.data ? `- ${props.data.name3}` : "loading..."}</p>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#myCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#myCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
